import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './index.css'

import App from './App'
import PrivateRoute from './PrivateRoute'
import CartDisplay from './CartDisplay'
import Provision from './Provision'

// import i18n (needs to be bundled ;))
import './i18n'

ReactDOM.render(
<BrowserRouter>
  <App>
    <Routes>
      <Route exact path="/cart_display" element={<PrivateRoute/>}>
        <Route exact path="/cart_display" element={<CartDisplay/>}/>
      </Route>
      <Route exact path="/provision" element={<Provision/>} />
      <Route exact path="/" element={<Navigate to="/cart_display" />} />
    </Routes>
  </App>
</BrowserRouter>,
  document.getElementById('root')
)


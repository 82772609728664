const styles = ({
  wallpaper,
  sideBanner
} = {}) => ({
  container: {
    height: '100vh',
    backgroundImage: `url(${wallpaper})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  sideBanner: {
    height: '100vh',
    backgroundImage: `url(${sideBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  languageFab: {
    margin: 20
  },
  cartItems: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '70vh'
  },
  cartSummary: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    height: '30vh',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  cartSummaryPrimary: {
    fontSize: 18,
    margin: 0
  },
  cartSummarySecondary: {
    fontSize: 16,
    color: '#979797'
  },
  cartSummaryGrandTotalLabel: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  cartSummaryGrandTotalValue: {
    fontSize: 30,
    textAlign: 'right',
    fontWeight: 'bold'
  }
})

export default styles

import _ from 'lodash'
import React from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
// import ListItemAvatar from '@mui/material/ListItemAvatar'
// import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import { useTranslation, withTranslation } from 'react-i18next'

import formatCurrency from '../helpers/format-currency'
import CartItem from '../ui/cart-item'
import ListItemContent from '../ui/list-item-content'
import defaultWallpaper from '../assets/wallpaper.png'
import defaultSideBanner from '../assets/side_banner.png'
import styles from './cart-display-styles'

function CartDisplayView({
  cart,
  cartItemList,
  locale,
  membershipNumber,
  orderTotalBreakdown,
  totalPrice,
  wallpaper = defaultWallpaper,
  sideBanner = defaultSideBanner,
} = {}) {
    const { t, i18n } = useTranslation()
    const changeToLanguage = i18n.language === 'en-HK' ? 'zh-HK' : 'en-HK'

    const scrollEndRef = React.useRef(null)

    // All event handlers or callbacks MUST start with "on"
    const onChangeLanguage = (lng) => i18n.changeLanguage(lng)

    React.useEffect(() => {
      if (locale !== i18n.language) {
        onChangeLanguage(locale)
      }
    }, [locale])

    React.useEffect(() => {
      // scroll cart item list to bottom on every cart change.
      if (scrollEndRef.current) {
        scrollEndRef.current.scrollIntoView({ behaviour: "smooth" })
      }
    }, [cartItemList])

    // if cart is empty then show placeholder
    if (_.isEmpty(cartItemList)) return <div style={styles({ wallpaper }).container}></div>

    return (
      <Grid container>
        <Grid item xs={0} md={6} style={styles({ sideBanner }).sideBanner}>
          { false && <div style={styles().languageFab}>
            <Fab aria-label="toggle-language" size="large" onClick={() => onChangeLanguage(changeToLanguage)}>
              {t(`fab.language.${changeToLanguage}`)}
            </Fab>
          </div> }
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={styles().cartItems}>
            <List>
              { cartItemList.map( item => (
                <CartItem
                  key={item.group_uuid}
                  {...{
                    cart,
                    item,
                    disabled: false,
                  }}
                />
              )) }
              <li ref={scrollEndRef} />
            </List>
          </div>
          <div style={styles().cartSummary} className="cart-display__summary">
            <Divider variant="middle" />
            <List dense={true}>
              <ListItem dense={true}>
                <ListItemText
                  disableTypography={true}
                  primary={t('cart.membership_no_with_value', { code: membershipNumber || 'N/A'})}
                />
              </ListItem>
              {orderTotalBreakdown.map(item => <ListItemContent
                key={`cart-breakdown-summary-${item.key}`}
                left={{ [item.secondary ? 'secondary' : 'primary']: item.label || t(`cart.summary.${item.key}`), style: styles().cartSummaryPrimary }}
                right={{ [item.secondary ? 'secondary' : 'primary']: formatCurrency(item.value), style: styles().cartSummaryPrimary }}
                dense={item.secondary}
              />)}
            </List>
            <List dense={true}>
              <ListItemContent
                left={{
                  primary: t('cart.total_with_count', { count: (cartItemList || []).length }),
                  style: styles().cartSummaryGrandTotalLabel
                }}
                right={{
                  primary: formatCurrency(totalPrice),
                  style: styles().cartSummaryGrandTotalValue
                }}
              />
            </List>
          </div>
        </Grid>
      </Grid>
    )
}

export default withTranslation()(CartDisplayView)

import _ from 'lodash'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'

import formatCurrency from '../helpers/format-currency'

const styles = () => {
  const primary = {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  }
  const secondary = {
    fontSize: 15,
    color: '#979797',
  }
  return {
    primary,
    secondary,
    errors: {
      color: 'red',
    },
    disabled: {
      backgroundColor: 'lightgrey'
    },
    unitPrice: {
      ...secondary,
      marginBottom: 5
    }
  }
}

function getCartLinePropertyError(cartLineProperty) {
  const errorFlags = _.pick(cartLineProperty, [
    'is_exceed_maximum_sku_select',
    'is_not_reach_minimum_sku_select',
    'is_invalid_addon',
    'is_exceed_maximum_per_sku_quantity',
    'is_not_reach_minimum_per_sku_quantity',
    'is_exceed_maximum_total_quantity',
    'is_not_reach_minimum_total_quantity',
    'sku_is_inactive',
  ])
  const errors = _.keys(errorFlags).filter((key) => errorFlags[key])
  return errors
}

function CartItem({
  // TODO: clean up
  cart,
  disabled,
  item,
  itemProps,
  // void_item_reasons
} = {}) {
  const { t } = useTranslation()
  const addons = _.get(item, 'addons', [])
  const sku = _.get(item, 'sku', {})
  const isVoidItem = _.get(item, 'soft_delete', false)
  // const voidReasonId = _.get(main, 'cart_line_property_void_reason_id')
  // const voidReasonRemark = _.get(main, 'cart_line_property_void_reason_remark')
  // const voidReason = _.get(_.find(void_item_reasons, ['id', voidReasonId]), 'name', '')
  // const voidReasonWithRemark = _.isEmpty(voidReasonRemark) ? voidReason : `${voidReason}: ${voidReasonRemark}`
  const lineErrors = getCartLinePropertyError(item)
  const listItemStyle = {
    ...styles().item,
  }
  const manual_cart_discounts = _.filter(_.get(cart, 'cart_manual_cart_discounts', []), ['cart_line_property_identifier_uuid', _.get(item, 'identifier_uuid')])
  const groupCartDiscountTokens = _.filter(
    _.get(item, 'price_details.main_property_discount_group')
    || _.get(item, 'price_details.main_property_discount_breakdown', []),
    ({target_type}) => (_.includes(['CartDiscount', 'CartCouponToken'], target_type))
  )
  const mainSalesRemark = _.get(item, 'sales_remark')

  return (
    <React.Fragment>
      <ListItem
        style={listItemStyle}
        disabled={disabled}
        alignItems={"flex-start"}
        className={"highlight"}>
        <ListItemAvatar style={{ minWidth: '7%', marginRight: 5 }}>
          <div style={styles().primary}>{item.quantity}</div>
        </ListItemAvatar>
        <ListItemText
          style={{ maxWidth: '65%' }}
          disableTypography={true}
          primary={<div style={styles().primary}>{sku.internal_use_title}</div>}
          secondary={
            <React.Fragment>
              <div style={styles().unitPrice}>
                { sku.use_measurement
                  ? `${item.sku_measure}${item.sku_measure_unit} @ ${formatCurrency(_.get(item, 'price_details.unit_sku_price', '0.0'))}`
                  : formatCurrency(_.get(item, 'price_details.main_property_unit_price', '0.0')) }
                { sku.use_measurement ? ` / ${item.sku_measure_unit}` : null }
              </div>
              { _.map(_.groupBy(addons, 'sku.code'), (groupedAddons, addonSkuCode) => {
                const addonName = _.get(_.first(groupedAddons), 'sku.internal_use_title')
                const isSkipItem = _.get(_.first(groupedAddons), 'sku.meta.fnb_skip_modifier', false)
                const addonErrors = getCartLinePropertyError(_.first(groupedAddons))
                const addonSubtotal = _.get(_.first(groupedAddons), 'price_details.property_unit_price', "0.0")
                const price = parseFloat(addonSubtotal) > 0 ? `(+${formatCurrency(addonSubtotal)})` : ''
                const qty = groupedAddons.length > 1 ? `x${groupedAddons.length}` : ''

                if (isSkipItem) return <div style={styles().secondary}>{`- ${addonName}`}</div>

                return (
                  <React.Fragment key={_.get(_.first(groupedAddons), 'identifier_uuid')}>
                    <div style={styles().secondary}>{`- ${addonName} ${price}${qty}`}</div>
                    { addonErrors.map((addonErrorKey) => {
                      const identifier_uuid = _.get(_.first(groupedAddons), 'identifier_uuid')
                      return (
                        <div style={styles().errors} key={`addon_error_${identifier_uuid}_${addonErrorKey}`}>
                          {`- ${t(`cart.item.error.${addonErrorKey}`, {defaultValue: t('cart.item.invalid')})}`}
                        </div>
                      )
                    }) }
                    { _.map(groupedAddons, ({sales_remark, identifier_uuid}) => (
                      !_.isEmpty(sales_remark)
                        ? <div style={styles().secondary} key={`addons_sales_remark_${identifier_uuid}`}>
                            <pre>{sales_remark}</pre>
                          </div>
                        : null
                    )) }
                  </React.Fragment>
                )
              }) }
              { !_.isEmpty(mainSalesRemark) &&
                <div style={styles().secondary}>
                  <pre style={styles().itemSalesRemarkText}>{mainSalesRemark}</pre>
                </div> }
              { !isVoidItem && (
                <React.Fragment>
                  { _.map(manual_cart_discounts, ({related_item_id, id}) => {
                    const cartCouponTokens = _.get(cart, 'cart_coupon_tokens', [])
                    const cartCouponToken = _.find(cartCouponTokens, ['coupon_token.id', Number.parseInt(related_item_id, 10)])
                    const couponToken = _.get(cartCouponToken, 'coupon_token')
                    const groupCartDiscountTokensTargetIds = _.map(groupCartDiscountTokens, 'target_id')
                    const isInsideGroupCartDiscountTokens = _.includes(groupCartDiscountTokensTargetIds, cartCouponToken.id)
                    const errors = _.get(couponToken, 'errors')
                    const error_keys = _.keys(errors)
                    return !_.isEmpty(couponToken) && !isInsideGroupCartDiscountTokens && (
                      <React.Fragment key={`cart-item-coupon-token-${_.get(couponToken, 'id')}`}>
                        <div style={styles().secondary}>{`- ${_.get(couponToken, 'coupon.name')}`}</div>
                        { !_.isEmpty(error_keys) && error_keys.map((error_key) => (
                          <div style={styles().errors}>
                            {
                              t(
                                `cart.order_discounts.error_${error_key}`,
                                { defaultValue: t('cart.order_discounts.error') }
                              )
                            }
                          </div>
                        )) }
                      </React.Fragment>
                    )
                  }) }
                  { _.map(groupCartDiscountTokens, ({title, total_amount, uuid, target_id}) => (
                    <div key={`cart-item-discount-token-${uuid}`} style={styles().secondary}>{`- ${title} (${formatCurrency(total_amount)})`}</div>
                  )) }
                </React.Fragment>
              ) }
              { lineErrors.map((lineErrorKey) => (
                <div style={styles().errors}>
                  {`- ${t(`cart.item.error.${lineErrorKey}`, {defaultValue: t('cart.item.invalid')})}`}
                </div>
              )) }
            </React.Fragment>
          }
        />
        <ListItemText
          disableTypography={true}
          style={{ textAlign: 'right', minWidth: '15%' }}
          primary={<div style={styles().primary}>{formatCurrency(_.get(item, 'price_details.main_property_subtotal_price', '0.0'))}</div>}
        />
      </ListItem>
    </React.Fragment>
  )
}

export default withTranslation()(CartItem)

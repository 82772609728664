import './App.css'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

function App({ children }) {
  const classes = useStyles()
  return (
<div className={classes.root}>
  {children}
</div>
  )
}

export default App

/**
 * currency
 *
 * A simple currency formatter which accepts a number and returns
 * a formatted currency value
 */
 import accounting from '../vendor/accounting';

 const Config = {
  CURRENCY_UNIT: '$',
  CURRENCY_DELIMITER: '.',
  CURRENCY_SEPARATOR: ',',
  CURRENCY_PRECISION: 2
 }

 function formatCurrency(num, options = {}) {
   const opts = {
     symbol: Config.CURRENCY_UNIT || undefined,
     decimal: Config.CURRENCY_DELIMITER || undefined,
     thousand: Config.CURRENCY_SEPARATOR || undefined,
     precision: Config.CURRENCY_PRECISION || undefined,
     ...options
   };
   return accounting.formatMoney(num, opts);
 }

 export default formatCurrency;

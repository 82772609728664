import React, { useState, useEffect, useRef } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
  const [isProcessing, setIsProcessing] = useState([])
  const [isAuth, setIsAuth] = useState([])

  const fetchConfig = async function (setIsAuth) {
    try {
      let apiUrl = localStorage.getItem('ApiUrl')
      let appId = localStorage.getItem('AppId')
      let appSecret = localStorage.getItem('AppSecret')
      let apiChannel = localStorage.getItem('ApiChannel') || 'ticket'
      let commerceChannel = localStorage.getItem('CommerceChannel') || 'pos'

      let url = `${apiUrl}/api/v2/en/${apiChannel}_${commerceChannel}/me?schema_version=2019-08-18&includes=full_set_model_configs`
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-ACCESS-KEY": appId,
          "X-SECRET-KEY": appSecret
        }
      })

      if (response.ok && (response.status >= '200' && response.status < '400')) {
        let resData = await response.json()
        let config = resData.data.internal_app.full_set_model_configs.find( x => x.key === 'pos.dual_display').value
        localStorage.setItem('SocketUrl', config.wsurl || '')
        localStorage.setItem('EventCode', config.event_code || '')
        localStorage.setItem('AccessKey', config.access_key || '')
        localStorage.setItem('Assets', JSON.stringify(config.assets || {}))
        localStorage.setItem('UsePostMessage', config.use_post_message || false)
        setIsAuth(true)
        setIsProcessing(false)
        return
      } else {
        console.log("Error #1", response)
      }
    } catch(err) {
      console.log("Error #2", err)
    }
    setIsAuth(false)
    setIsProcessing(false)
  }

  useEffect(() => {
    setIsProcessing(true)
    fetchConfig(setIsAuth)
  }, [])

  return (
isProcessing ? "Loading" : (isAuth ? <Outlet/> : <Navigate to="/provision" />)
  )
}

export default PrivateRoute;

import _ from 'lodash'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%'
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  }
}

const ListItemContent = ({
  left = undefined,
  right = undefined,
  dense = false
} = {}) => {
  const leftProps = {
    disableTypography: true,
    ..._.omit(left, 'style'),
    style: {
      ...styles.left,
      ...left.style,
    },
  }
  const rightProps = {
    disableTypography: true,
    ..._.omit(right, 'style'),
    style: {
      ...styles.right,
      ...right.style,
    },
  }
  return (
    <ListItem alignItems="flex-start" dense={true}>
      { !_.isUndefined(left) && <ListItemText {...leftProps} /> }
      { !_.isUndefined(right) && <ListItemText {...rightProps} /> }
    </ListItem>
  )
}

export default ListItemContent

import _ from 'lodash'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import io from 'socket.io-client'
import utf8 from 'utf8'
import { decode } from 'base-64'

import CartDisplayView from './cart-display-view'

// TEST ONLY!!!
// import cartDataJson from '../tests/cart_data.json'
// import skuMeasurementsDataJson from '../tests/sku_measurements_data.json'

// data structure of data
const defaultData = {
  cart: {},
  locale: 'en-HK',
  sku_measurements: []
}

const CartDisplay = () => {
  const [data, setData] = useState(defaultData)

  const eventCode = localStorage.getItem('EventCode')
  const accessKey = localStorage.getItem('AccessKey')
  const remoteAssets = JSON.parse(localStorage.getItem('Assets') || {})

  const orderTotalBreakdown = useMemo(() => {
    const total_discount = _.toNumber(_.get(data, 'cart.price_details.total_discount'))
    const total_voucher_amount = _.toNumber(_.get(data, 'cart.price_details.total_voucher_amount'))
    const discount_group = _.map(_.get(data, 'cart.price_details.discount_group', []), ({ uuid, title, total_amount }) => ({
      key: `discount-group-${uuid}`,
      label: title,
      value: total_amount,
      secondary: true
    }))
    const voucher_amount_group = _.map(_.get(data, 'cart.price_details.voucher_amount', []), ({ uuid, title, total_amount }) => ({
      key: `voucher-amount-group-${uuid}`,
      label: title,
      value: total_amount,
      secondary: true
    }))

    // discount then service charge then voucher
    // voucher at last one, coz it also - service charge
    return _.compact([
      {
        key: 'subtotal',
        value: _.get(data, 'cart.price_details.subtotal_price'),
      },
      {
        key: 'service_charge',
        value: _.get(data, 'cart.price_details.service_charge'),
        secondary: true
      },
      {
        key: 'discounts',
        value: total_discount,
        secondary: true
      },
      // ...discount_group,
      {
        key: 'voucher',
        value: total_voucher_amount,
        secondary: true
      },
      ...voucher_amount_group,
      _.has(data, 'cart.order_amount_rounding') ? {
        key: 'rounding',
        value: _.get(data, 'cart.order_amount_rounding'),
        secondary: true
      } : undefined,
    ])
  }, [data.cart])

  const cartItemList = useMemo(() => {
    let itemList = []
    _.get(data, 'cart.cart_line_properties', []).forEach((item) => {
      if (item.parent_identifier_uuid == null) {
        itemList.push({
          ...item,
          sku_measure_unit: _.get(_.find(_.get(data, 'sku_measurements', []), { id: item.sku_measurement_id }), 'name', null),
          addons: []
        })
      }
    })

    _.get(data, 'cart.cart_line_properties', []).forEach((item) => {
      if (item.parent_identifier_uuid != null) {
        itemList.forEach((listItem) => {
          if (item.group_uuid === listItem.group_uuid) {
            listItem.addons.push(item)
          }
        })
      }
    })

    return itemList
  }, [data.cart])

  useEffect(() => {
    // FOR TEST ONLY!!!
    // const _data = {
    //   cart: cartDataJson,
    //   sku_measurements: skuMeasurementsDataJson,
    //   locale: 'en-HK'
    // }
    // setData(_data)
    // console.log('%c _data', 'color:red', _data)

    let socket = null
    let socketUrl = null
    const usePostMessage = localStorage.getItem('UsePostMessage')

    if (usePostMessage) {
      try {
        document.addEventListener('webview:message', (e) => {
          const jsonMsg = JSON.parse(utf8.decode(decode(e.detail)))
          setData(jsonMsg.data)
        }, false)
      } catch(err) {
        console.log(err)
      }
    } else {
      socketUrl = localStorage.getItem('SocketUrl')

      if (socketUrl) {
        socket = io(socketUrl)

        const messageListener = (message) => {
          try {
            var jsonMsg = JSON.parse(message)
            setData(jsonMsg.data)
          } catch(err) {
            console.log(err)
          }
        }

        socket.on("connect", () => {
          console.log("Create Socket")
          socket.emit('cart_data_subscribe', JSON.stringify({
            channel:  eventCode,
            key:      accessKey
          }))
        })

        socket.on(eventCode, messageListener)
      }
    }

    return () => {
      if (usePostMessage) {
        // no clean up needed
      } else {
        if (socket) {
          console.log("Socket Closed")
          socket.close()
        }
      }
    }
  }, [])

  // Please explicitly define ALL props to pass to the view!
  return <CartDisplayView {...{
    cart: _.get(data, 'cart'),
    cartItemList,
    locale: _.get(data, 'locale'),
    membershipNumber: _.get(data, 'cart.consumer.membership_code'),
    orderTotalBreakdown,
    sideBanner: _.get(remoteAssets, 'sideBanner'),
    totalPrice: _.get(data, 'cart.price_details.total_price'),
    wallpaper: _.get(remoteAssets, 'wallpaper'),
  }} />
}

export default CartDisplay
